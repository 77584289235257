import Vue from "vue";
import Router from "vue-router";
import VueHead from "vue-head";

import Config from '@/conf/Config.js';
import axios from 'axios';

Vue.use(VueHead);
Vue.use(Router);

/* eslint-disable */
/** sweet alert */
import swal from "@/assets/swal/sweet-alert.min.js";
require("@/assets/swal/sweet-alert.css");
require("@/assets/swal/theme.css");

const router = new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/login",
            name: "Login",
            component: () => import("./views/Login.vue")
        },
        {
            path: "/home",
            name: "Dashboard",
            component: () => import('./views/Induk.vue'),
            meta: {
                auth: true,
                // title : 'Dashboard'
            },
            children: [
                // dashboard bh
                {
                    path: "/home",
                    name: "Main",
                    component: () => import("./views/Dashboard.vue"),
                    meta: {
                        auth: true,
                        // title : 'Main',
                    }
                },
                {
                    path: "/profile",
                    name: "Profile",
                    component: () => import("./views/Profile.vue"),
                    meta: {
                        auth: true,
                        // title : 'Profile',
                    }
                },
                {
                    path: "/d-customer",
                    name: "D-Customer",
                    component: () => import("./views/dashboard/Customer.vue"),
                    meta: {
                        auth: true,
                        // title : 'Main',
                    }
                },
                {
                    path: "/d-supplier",
                    name: "D-Supplier",
                    component: () => import("./views/dashboard/Supp.vue"),
                    meta: {
                        auth: true,
                        // title : 'Main',
                    }
                },
                {
                    path: "/checkin",
                    name: "Checkin",
                    component: () => import("./views/Checkin.vue"),
                    meta: {
                        auth: true,
                        // title : 'Checkin',
                    }
                },
                // master
                {
                    path: "/customer",
                    name: "Customer",
                    component: () => import("./views/master/Customer.vue"),
                    meta: {
                        auth: true,
                        // title : 'Customer',
                    }
                },
                {
                    path: "/location",
                    name: "Location",
                    component: () => import("./views/master/Location.vue"),
                    meta: {
                        auth: true,
                        // title : 'Location',
                    }
                },
                {
                    path: "/kendaraan",
                    name: "Kendaraan",
                    component: () => import("./views/master/Kendaraan.vue"),
                    meta: {
                        auth: true,
                        // title : 'Kendaraan',
                    }
                },
                {
                    path: "/gudang",
                    name: "Gudang",
                    component: () => import("./views/master/Gudang.vue"),
                    meta: {
                        auth: true,
                        // title : 'Gudang',
                    }
                },
                {
                    path: "/item",
                    name: "Item",
                    component: () => import("./views/master/Item.vue"),
                    meta: {
                        auth: true,
                        // title : 'Item',
                    }
                },
                {
                    path: "/simcard",
                    name: "Simcard",
                    component: () => import("./views/master/Simcard.vue"),
                    meta: {
                        auth: true,
                        // title : 'Simcard',
                    }
                },
                // packing
                {
                    path: "/stapel",
                    name: "Stapel",
                    component: () => import("./views/packing/Stapel.vue"),
                    meta: {
                        auth: true,
                        // title : 'Stapel',
                    }
                },
                // sales
                {
                    path: "/target",
                    name: "Target",
                    component: () => import("./views/sales/Target.vue"),
                    meta: {
                        auth: true,
                        // title : 'Target',
                    }
                },
                {
                    path: "/penjualan",
                    name: "Penjualan",
                    component: () => import("./views/sales/Penjualan.vue"),
                    meta: {
                        auth: true,
                        // title : 'Target',
                    }
                },
                {
                    path: "/kunjungan",
                    name: "Kunjungan",
                    component: () => import("./views/sales/Kunjungan.vue"),
                    meta: {
                        auth: true,
                        // title : 'Kunjungan',
                    }
                },
                // report
                {
                    path: "/itemstock",
                    name: "ItemStock",
                    component: () => import("./views/report/ItemStock.vue"),
                    meta: {
                        auth: true,
                        // title : 'Kunjungan',
                    }
                },
                // approved
                {
                    path: "/approved",
                    name: "Approved",
                    component: () => import("./views/approved/Approved.vue"),
                    meta: {
                        auth: true,
                        // title : 'Kunjungan',
                    }
                },
                {
                    path: "/pengajuan",
                    name: "Pengajuan",
                    component: () => import("./views/approved/Pengajuan.vue"),
                    meta: {
                        auth: true,
                        // title : 'Kunjungan',
                    }
                },
                {
                    path: "/approved-cash",
                    name: "Approved Cash",
                    component: () => import("./views/approved/Approvedc.vue"),
                    meta: {
                        auth: true,
                        // title : 'Kunjungan',
                    }
                },
                {
                    path: "/approved-histori",
                    name: "Approved Report",
                    component: () => import("./views/approved/Approvedh.vue"),
                    meta: {
                        auth: true,
                        // title : 'Kunjungan',
                    }
                },
                // item olshop
                {
                    path: "/dashboard-barang",
                    name: "Dashboard Barang",
                    component: () => import("./views/item/Produk.vue"),
                    meta: {
                        auth:true,
                    }
                },
                {
                    path: "/data-user",
                    name: "Data User App",
                    component: () => import("./views/master/User.vue"),
                    meta: {
                        auth:true,
                    }
                },
            ]
        }
    ]
});

router.beforeEach((to, from, next) => {
    try {
        if (to.matched.some(record => record.meta.auth)) {
            axios.defaults.baseURL = Config.apiUrl;
            axios.defaults.headers.common['Authorization'] = localStorage.tsalestoken;
            axios.post('admin/ApiAdmin.php',{
                pfunction : 'checklogin'
            }).then(function (response) {
                if (response.data.success == true) {
                    try {
                        next();
                    } catch (err) {
                        console.log(err);
                        next("/login");
                    }
                } else {
                    next("/login");
                }
            });
        }
        else {
            if (to.matched.length > 0){
                if (to.matched[0].name == 'Login') {
                    axios.defaults.baseURL = Config.apiUrl;
                    axios.defaults.headers.common['Authorization'] = localStorage.tsalestoken;
                    axios.post('admin/ApiAdmin.php',{
                        pfunction : 'checklogin'
                    }).then(function (response) {
                        if (response.data.success == true) {
                            try {
                                next();
                            } catch (err) {
                                console.log(err);
                                next("/login");
                            }
                        } else {
                            next("/login");
                        }
                    });
                }
            }

            if (to.matched.length > 0) {
                next();
            } else {
                next("/home");
            }
        }
    } catch (error) {
        console.log('router error1', error);
    }
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start();
    }
    next()
});


router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done()
});

export default router;